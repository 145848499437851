import React from 'react'
import moment from 'moment-timezone'

class Time extends React.Component {

  state = {
    time: ''
  }

  componentDidMount() {
    this.setTime()
    this.seconds = setInterval(this.setTime, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.seconds)
  }

  setTime = () => {
    const time = moment().tz("Europe/London").add(this.props.timezone, 'hour').format('HH:mm')
    this.setState({
      time: time
    })
  }

  render() {
    return (
      <span>{this.state.time}</span>
    )
  }
}

export default Time
