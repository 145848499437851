import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

class Jobs extends React.Component {

  state = {
    feed: null
  }

  render() {

    const feed = this.props.allPurpleJob.edges
    const isEmpty = feed[0].node.empty

    return feed && !isEmpty ? (
      <div className="span-2">
        <h3 className="upcase mb">Current vacancies</h3>
        <ul className="jobfeed">
          {feed.map(({node}, i) => {
            // console.log(node)
            return (
              <li key={`job-${i}`}>
                <p>Job Title: <br /> {node.jobTitle || node.title}</p>
                <p>Location: <br /> {node.location}</p>
                <p>
                  <a href={node.link} target="_blank" rel="noopener noreferrer">Apply now</a>
                </p>
              </li>
            )
          })}
        </ul>

      </div>
    ) : (
      <div>
        <h3 className="upcase mbx2">Current vacancies</h3>
        <p>Currently there are no vacancies, please check back soon.</p>
      </div>
    )
  }
}

const Content = (props) => (
  <StaticQuery
    query={jobsQuery}
    render={data => <Jobs {...data} {...props} />}
  />
)

export default Content

const jobsQuery = graphql`
  query jobsQuery {
    allPurpleJob {
      edges {
        node {
          title
          description
          link
          jobUrl
          vacancyName
          vacancyDescription
          location
          department
          jobTitle
          salaryRange
          experience
          city
          closingDate
          company
          reference
          empty
        }
      }
    }
  }
`
