import React from 'react'

const Markdown = ({className, html}) => (
  <div className={`markdown ${className}`} dangerouslySetInnerHTML={{__html: html}} />
)

Markdown.defaultProps = {
  className: '',
  html: ''
}

export default Markdown
