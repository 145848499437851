import React from 'react'

class Section extends React.Component {

  state = {
    showContent: false,
    isButtonVisible: this.props.currentPage === '/'
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false)
  }

  onScroll = () => {
    let { top } = this.refs.position.getBoundingClientRect();

    if (top >= this.props.navTop && !this.state.isButtonVisible) {
      this.setState({
        isButtonVisible: true
      })
    } else if (top < this.props.navTop && this.state.isButtonVisible) {
      this.setState({
        isButtonVisible: false
      })
    }
  }

  render() {

    const { nextPage, path, isChangingPage, innerRef, children } = this.props

    const isNextPage = nextPage && nextPage.includes(path)

    return (
      <section ref={innerRef} style={{
        opacity: !isChangingPage || (isChangingPage && isNextPage) ? 1 : 0,
        transition: 'opacity 0.75s'
      }}>
        <div ref="position" />
        <button className={this.props.buttonClassName} onClick={this.props.onLinkClick} style={{
          opacity: this.state.isButtonVisible ? 1 : 0,
          transition: 'opacity 0.75s',
        }}>{this.props.label}</button>
        {children}
      </section>
    )
  }
}

export default Section
