import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import { scrollTo } from '../utils/helpers'
import Asset from '../components/asset'
import Header from '../components/header'
import Jobs from '../components/jobs'
import Layout from '../components/layout'
import Markdown from '../components/markdown'
import Projects from '../components/projects'
import Section from '../components/section'
import SEO from '../components/seo'
import Time from '../components/time'
import Weather from '../components/weather'

class Wrapper extends React.Component {
  state = {
    ready: false
  }
  componentDidMount() {
    this.timer = setTimeout(this.isReady, 200)
  }
  componentWillUnmount() {
    clearTimeout(this.timer)
  }
  isReady = () => {
    this.setState({
      ready: true
    })
  }
  render() {
    return (
      <div className="nav-section" style={{
        opacity: this.state.ready ? 1 : 0,
        transition: '0.75s',
        ...this.props.style
      }}>
        {this.props.children}
      </div>
    )
  }
}

class Navigation extends React.Component {

  state = {
    currentPage: this.props.location.pathname,
    nextPage: null,
    isChangingPage: false,
    navTop: 168,
    top: 0
  }

  componentDidMount() {

    const navTop = this.refs.nav.offsetTop

    this.setState({
      navTop: navTop
    })

    if (this.topEl) {
      window.scrollTo(0, this.topEl.offsetTop - navTop)
    }
  }

  navigateTo = (path, backPath, event) => {

    const isLocation = path.includes('locations');
    const isDifferentPage = path !== this.state.currentPage
    const to = isDifferentPage ? path : backPath;
    const linkEl = isDifferentPage ? (isLocation ? this.locationsEl : event.target) : (this.currentSectionEl);
    const endY = to === '/' ? 0 : linkEl.offsetTop - this.state.navTop;

    this.setState({
      nextPage: to,
      isChangingPage: true
    })

    scrollTo(endY, 0.75, () => {

      // New route state...
      this.setState({
        currentPage: to,
        nextPage: null,
      })

      const finalY = to === '/' ? 0 : linkEl.offsetTop - this.state.navTop;

      // Recaluclate active section position and set scroll position...
      window.scrollTo(0, finalY)

      // Update url
      this.updateUrl(to)

      // Finally stop the page transition in state...
      setTimeout(() => {
        this.setState({
          isChangingPage: false
        })
      }, 0)
    })
  }

  isMatch(path) {
    return this.state.currentPage.includes(path)
  }

  isSubNavOpen(slug) {
    const split = this.state.currentPage.split(slug)
    const hasSlug = split.length > 1
    if (hasSlug) {
      return split[1].length > 0
    } else {
      return false
    }
  }

  updateUrl(pathname) {
    const { origin } = window.location
    const newUrl = pathname ? `${origin}${pathname}` : `${origin}`
    window.history.pushState({path: newUrl}, '', newUrl);
  }

  render() {

    const {
      title,
      description,
      profile,
      profileSecondColumn,
      projects,
      divisions,
      locations,
      culture
    } = this.props.settings.edges[0].node

    const isHome = this.state.currentPage === '/'

    return (
      <Layout path={this.state.currentPage}>
        <Header navigateTo={this.navigateTo} locationsEl={this.locationsEl} />

        <SEO title={title} description={description} />
        <nav ref="nav" style={{
          paddingBottom: this.state.isChangingPage ? '100vh' : isHome ? '0' : '66vh'
        }}>

          {/* Profile */}
          <Section
            {...this.state}
            path={'/profile'}
            match={this.isMatch('/profile')}
            innerRef={this.isMatch('/profile') ? o => this.topEl = o : null}
            onLinkClick={this.navigateTo.bind(this, '/profile', '/')}
            buttonClassName="h1"
            label={'Profile'}
          >
            {this.isMatch('/profile') ? (
              <Wrapper>
                <div className="profile-columns max outerx2">
                  <Markdown html={profile.childMarkdownRemark.html} />
                  {!profileSecondColumn || (
                    <Markdown html={profileSecondColumn.childMarkdownRemark.html} />
                  )}
                </div>
              </Wrapper>
            ) : null}
          </Section>

          {/* Our Work */}
          <Section
            {...this.state}
            path={'/work'}
            match={this.isMatch('/work')}
            innerRef={this.isMatch('/work') ? o => this.topEl = o : null}
            onLinkClick={this.navigateTo.bind(this, '/work', '/')}
            buttonClassName="h1"
            label={'Our Work'}
          >
            {this.isMatch('/work') ? (
              <Wrapper>
                <Projects projects={projects} divisions={divisions} location={this.props.location} />
              </Wrapper>
            ) : null}
          </Section>

          {/* Expertise */}
          <Section
            {...this.state}
            path={'/expertise'}
            hasSubNav={true}
            match={this.isMatch('/expertise')}
            innerRef={this.isMatch('/expertise') && !this.isSubNavOpen('expertise') ? o => this.topEl = o : this.isMatch('/expertise') ? o => this.currentSectionEl = o : null}
            onLinkClick={this.navigateTo.bind(this, '/expertise', '/')}
            buttonClassName="h1"
            label={'Expertise'}
            isSubNavOpen={this.isSubNavOpen('expertise')}
          >
            {this.isMatch('/expertise') ? (
              <Wrapper>
                <div className="sub-nav-section">
                  {divisions.map(({title, slug, copy, contactDetails, thumbnail}) => (
                    <Section
                      {...this.state}
                      path={`/expertise/${slug}`}
                      match={this.isMatch(`/expertise/${slug}`)}
                      isSubNav={true}
                      innerRef={this.isMatch(`/expertise/${slug}`) ? o => this.topEl = o : null}
                      onLinkClick={this.navigateTo.bind(this, `/expertise/${slug}/`, '/expertise')}
                      buttonClassName="h2"
                      label={title}
                      key={`expertise-${slug}`}
                    >
                      {this.isMatch(`/expertise/${slug}`) ? (
                        <Wrapper>
                          <div className="division-info">
                            {!copy || (
                              <Markdown className="division-copy span-2" html={copy.childMarkdownRemark.html} />
                            )}
                            {!contactDetails || (
                              <div className="span-1">
                                <h3 className="upcase mbx2 ">Contact</h3>
                                <Markdown html={contactDetails.childMarkdownRemark.html} />
                              </div>
                            )}
                            {!thumbnail || (
                              <div className="span-1">
                                <figure>
                                  <Asset {...thumbnail} />
                                </figure>
                              </div>
                            )}
                          </div>
                        </Wrapper>
                      ) : null}
                    </Section>
                  ))}
                </div>
              </Wrapper>
            ) : null}
          </Section>

          {/* Locations */}
          <Section
            {...this.state}
            path={'/locations'}
            match={this.isMatch('/locations')}
            innerRef={ref => {
              if (this.isMatch(`/locations`)) {
                this.topEl = ref
              }
              this.locationsEl = ref
            }}
            onLinkClick={this.navigateTo.bind(this, '/locations', '/')}
            buttonClassName="h1"
            label={'Locations'}
          >
            {this.isMatch('/locations') ? (
              <Wrapper>
                <div className="locations-grid outerx4">
                  {locations.map(({city, contactDetails, location, units, timezone}, i) => {
                    return (
                      <div key={city}>
                        <h3 className="mb upcase">
                          <span>{city} — </span>
                          <Time timezone={timezone} />
                          <span> </span>
                          <Weather coords={location} isMetric={units} getWeather={i === 0} />
                        </h3>
                        <Markdown html={contactDetails.childMarkdownRemark.html} />
                      </div>
                    )
                  })}
                </div>
              </Wrapper>
            ) : null}
          </Section>

          {/* Culture */}
          <Section
            {...this.state}
            path={'/culture'}
            hasSubNav={true}
            match={this.isMatch('/culture')}
            innerRef={this.isMatch('/culture') && !this.isSubNavOpen('culture') ? o => this.topEl = o : this.isMatch('/culture') ? o => this.currentSectionEl = o : null}
            onLinkClick={this.navigateTo.bind(this, '/culture', '/')}
            buttonClassName="h1"
            label={'Culture'}
          >
            {this.isMatch('/culture') ? (
              <Wrapper style={{ minHeight: '60vh' }}>
                <div className="sub-nav-section">
                  {culture.map(({title, slug, copy}) => (
                    <Section
                      {...this.state}
                      path={`/culture/${slug}`}
                      match={this.isMatch(`/culture/${slug}`)}
                      isSubNav={true}
                      innerRef={this.isMatch(`/culture/${slug}`) ? o => this.topEl = o : null}
                      onLinkClick={this.navigateTo.bind(this, `/culture/${slug}/`, '/culture')}
                      buttonClassName="h2"
                      label={title}
                      key={`culture-${slug}`}
                    >
                      {this.isMatch(`/culture/${slug}`) ? (
                        <Wrapper>
                          <div className="division-info">
                            {!copy || (
                              <Markdown className="division-copy span-2" html={copy.childMarkdownRemark.html} />
                            )}
                            {slug !== 'careers' || (
                              <Jobs />
                            )}
                          </div>
                        </Wrapper>
                      ) : null}
                    </Section>
                  ))}
                </div>
              </Wrapper>
            ) : null}
          </Section>
        </nav>
      </Layout>
    )
  }
}

const PageTemplate = ({data, isHome}) => (
  <Location>
    {({location}) => (
      <Navigation {...data} location={location} isHome={isHome} />
    )}
  </Location>
)

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageTemplate

export const settingsFragment = graphql`
  fragment Settings on ContentfulSettings {
    title
    description
    # Profile content
    profile {
      childMarkdownRemark {
        html
      }
    }
    profileSecondColumn {
      childMarkdownRemark {
        html
      }
    }
    # Our work
    projects: ourWork {
      ...ProjectInfo
    }
    # Divisions
    divisions {
      title
      slug
      copy {
        childMarkdownRemark {
          html
        }
      }
      contactDetails {
        childMarkdownRemark {
          html
        }
      }
      # thumbnail {
      #   file {
      #     url
      #   }
      #   fluid(maxWidth: 400, quality: 70) {
      #     srcSet
      #     sizes
      #   }
      # }
    }
    # Locations
    locations {
      city
      units
      timezone
      location {
        lon
        lat
      }
      contactDetails {
        childMarkdownRemark {
          html
        }
      }
    }
    # Culture
    culture {
      title
      slug
      copy {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export const projectFragment = graphql`
  fragment ProjectInfo on ContentfulProject {
    title
    slug
    externalLink
    thumbnail {
      file {
        contentType
        url
      }
      fluid(maxWidth: 400, quality: 90) {
        srcSet,
        sizes,
        src,
        aspectRatio
      }
    }
    division {
      title
      slug
    }
  }
`
