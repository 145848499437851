import React from 'react'
import PropTypes from 'prop-types'
import Picture from '../components/picture'

class Asset extends React.Component {

  render() {

    const { fluid, file, alt } = this.props

    return fluid && fluid.srcSet ? (
      <Picture {...fluid} alt={alt ? alt : ``} />
    ) : file && file.url ? (
      <video src={file.url} autoPlay loop muted preload="meta" />
    ) : null
  }
}

Asset.propTypes = {
  fluid: PropTypes.object,
  file: PropTypes.object,
  alt: PropTypes.string,
}

export default Asset
