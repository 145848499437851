export const scrollTo = (endY, duration, callback) => {
  const from = document.documentElement.scrollTop || window.scrollY
  const by = endY - from
  let currentIteration = 0
  const animIterations = Math.round(60 * duration)
  const easeOutCubic = (currentIteration, startValue, changeInValue, totalIterations) => {
    return changeInValue * (Math.pow(currentIteration / totalIterations - 1, 3) + 1) + startValue;
  }
  const scroll = () => {
    const value = easeOutCubic(currentIteration, from, by, animIterations);
    window.scrollTo(0, value)
    currentIteration++;
    if (currentIteration < animIterations) {
      window.requestAnimationFrame(scroll);
    } else {
      window.cancelAnimationFrame(scroll);
      callback()
      // window.scrollTo(0, endY)
    }
  }
  scroll()
}
