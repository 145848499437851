import React from 'react'
import Asset from '../components/asset'

class FilterMenu extends React.Component {

  state = {
    open: false
  }

  onFilterClick(filter, event) {
    this.props.onToggle()
    this.props.onFilter(filter)
  }

  render() {
    return (
      <div className="filter-menu mrx2">
        <button className="filter-label" onClick={this.props.onToggle}>{`Filter by: ${this.props.currentFilter}`}</button>
        {!this.props.open || (
          <div className="filter-links">
            <button className="filter" onClick={this.onFilterClick.bind(this, {title: 'All', slug: 'all'})}>All</button>
            {this.props.filters.map((node) => {
              return <button className="filter" onClick={this.onFilterClick.bind(this, node)} key={node.slug}>{node.title}</button>
            })}
          </div>
        )}
      </div>
    )
  }
}

class Projects extends React.Component {

  state = {
    currentSort: 'Desc',
    currentFilter: 'All',
    filterBy: null,
    page: 1,
    filterOpen: false
  }

  componentDidMount() {
    if (this.props.location && this.props.location.search) {
      const search = this.props.location.search.split('=')[1]
      const filter = this.props.divisions.find(item => item.slug === search)
      this.onFilter(filter)
    }
  }

  onToggle = () => {
    this.setState(last => {
      return {
        filterOpen: !last.filterOpen
      }
    })
  }

  onFilter = (filter) => {
    this.setState({
      currentFilter: filter.slug !== 'all' ? filter.title : 'All',
      filterBy: filter.slug !== 'all' ? filter.slug : null
    })
    this.updateUrl(filter.slug !== 'all' ? filter.slug : null)
  }

  updateUrl(search) {
    const { origin, pathname } = window.location
    const searchUrl = search ? `${origin}${pathname}?=${search}` : `${origin}${pathname}`
    window.history.pushState({path: searchUrl}, '', searchUrl);
  }

  render() {

    const { projects, divisions } = this.props
    const { filterBy } = this.state

    return (
      <div>
        <div className="h3 outer">
          {/* <a href="/" className="mrx2 upcase">Filter:</a> */}
          <FilterMenu filters={divisions} label={'Division'} onFilter={this.onFilter} currentFilter={this.state.currentFilter} open={this.state.filterOpen} onToggle={this.onToggle} />
        </div>
        <div className="work-thumbnails outerx4" style={{
          opacity: !this.state.filterOpen ? 1 : 0.2,
          transition: 'opacity 0.75s'
        }}>
          {projects.map((node, i) => {
            const landscape = node.thumbnail.fluid.aspectRatio > 1 || node.thumbnail.file.contentType === 'video/mp4'
            return (!filterBy || (node.division && node.division.slug === filterBy)) ? (
              <figure className={`thumbnail ${landscape ? 'span-2' : 'span-1'}`} key={`project-${i}`}>
                <a href={node.externalLink} target="_blank" rel="noopener noreferrer">
                  <Asset {...node.thumbnail} />
                  <figcaption className="p1">{node.title}</figcaption>
                </a>
              </figure>
            ) : null
          })}
        </div>
      </div>
    )
  }
}

export default Projects
