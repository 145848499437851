import React from 'react'
import axios from 'axios'

const ICONS = {
  '01d': '☀️',
  '01n': '🌙',
  '02d': '🌥',
  '02n': '🌙',
  '03d': '☁️',
  '03n': '🌙',
  '04d': '☁️',
  '04n': '🌙',
  '09d': '🌧',
  '09n': '🌙',
  '10d': '🌦',
  '10n': '🌙',
  '11d': '⛈',
  '11n': '🌙',
  '13d': '🌨',
  '13n': '🌙',
  '50d': '☁️',
  '50n': '🌙',
}

class Weather extends React.Component {

  state = {
    temp: '',
    icon: ''
  }

  componentDidMount() {
    this.getWeather()
    // TODO: Save this state to localStorage with a timestamp, update only if it is a day old
  }

  getWeather() {
    const { lon, lat } = this.props.coords
    const weatherKey = 'a1d308ccb7b8374d8479470ebbe2813d'
    const units = this.props.isMetric ? 'metric' : 'imperial'
    const weatherURL = `//api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=${units}&appid=${weatherKey}`

    axios
      .get(weatherURL)
      .then((response) => {

        // console.log(response)

        this.setState({
          temp: `${Math.round(response.data.main.temp)}°${this.props.isMetric ? 'C' : 'F'}`,
          icon: ICONS[response.data.weather[0].icon]
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  render() {

    return (
      <span>
        <span>{this.state.temp} </span>
        <span>{this.state.icon}</span>
      </span>
    )
  }
}

export default Weather
