import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Time from '../components/time'

const Header = ({navigateTo, locationsEl}) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allContentfulSettings (
          limit: 1
        ) {
          edges {
            node {
              locations {
                cityCode
                timezone
              }
            }
          }
        }
      }
    `}
    render={data => {

      const { locations } = data.allContentfulSettings.edges[0].node

      return (
        <header className="upcase">
          <button className="logo mb" onClick={navigateTo.bind(this, '/', '/')}>PURPLE</button>
          <button className="h3" onClick={navigateTo.bind(this, '/locations', '/')}>
            {locations.map((node, i) => (
              <span key={node.cityCode}>
                <span>{node.cityCode} </span>
                <Time {...node} />
                {i+1 < locations.length ? <span> / </span> : null}
              </span>
            ))}
          </button>
        </header>
      )
    }}
  />


)

export default Header
